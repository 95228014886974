import React, { useEffect, useState } from 'react';
import { API } from '../../env';
import Pagination from '../../components/pagination/Pagination';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Inventory-report.css'; // Ensure this CSS file is created for styling

const InventoryReport = () => {
    localStorage.setItem('selectedView', 'inventory-report');
    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [paginator, setPaginator] = useState({});

    useEffect(() => {
        const fetchReportData = async () => {
            setIsLoading(true);
            try {
                const data = new FormData();
                data.append('page', page);
                data.append('pageSize', pageSize);

                const url = new URL(`${API}/api/v1/reports/inventoryReport`);
                url.search = new URLSearchParams(data).toString();

                const response =   await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            });
                if (response.ok) {
                    const result = await response.json();
                    setPaginator(result);
                } else {
                    throw new Error('Failed to fetch report data');
                }
            } catch (error) {
                console.error("Error fetching report data", error);
                toast.error("Error fetching report data", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchReportData();
    }, [page]);

    const handlePageChange = (page) => {
        setPage(page);
    };
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
    };
const handleDownload = async () => {
    try {
        const response = await fetch(`${API}/api/v1/reports/download-inventory-report`, {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('jwtToken'),
                'Content-Type': 'application/octet-stream'
            }
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Inventory-Report-'+formatDate(new Date())+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else {
            throw new Error('Failed to download report');
        }
    } catch (error) {
        console.error("Error downloading report", error);
        toast.error("Error downloading report", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000
        });
        }
    };

    return (
        <div className="reports-container">
            <h1>Inventory Report</h1>
            < div className='download-container'>
            < button onClick={handleDownload} className="download-button">
                 Download Report
            </button>
  </div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="tabled-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Item Code</th>
                                <th>Present Stock</th>
                                <th>P-Price</th>
                                <th>S-Price</th>
                                <th>Stock Value</th>
                                <th>Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginator.articles && paginator.articles.length > 0 ? (
                                paginator.articles.map((entry, index) => (
                                    <tr key={entry.articleid}>
                                        <td>{entry.name}</td>
                                        <td>{entry.itemCode}</td>
                                        <td>{entry.stock}</td>
                                        <td>{entry.purchasePrice}</td>
                                        <td>{entry.salePrice}</td>
                                        <td>{entry.stock * entry.purchasePrice}</td>
                                        <td>{entry.provider.name}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No results found</td>
                                </tr>
                            )}
                            {paginator.page === paginator.totalPages && paginator.articles && (
                             <tr style={{ backgroundColor: 'lightblue', color: 'black' }}>
                             
                            <td style={{ fontWeight: 'bold' }}>Total</td>
                            <td></td>
                             <td style={{ fontWeight: 'bold' }}>{paginator.totalStock}</td>
                             <td></td>
                             <td></td>
                             <td style={{ fontWeight: 'bold' }}>{paginator.totalPrice}</td>
                             <td></td>
                             
                         </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination paginator={paginator} onChangePage={handlePageChange} />
                </div>
            )}
        </div>
    );
};

export default InventoryReport;
