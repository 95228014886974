import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userVerification from '../../../utils/userVerification';
import { API } from '../../../env';
import './detail-sale.css';
import Loading from '../../../components/loading/Loading';

const DetailSale = () => {
    localStorage.setItem('selectedView', 'sales');
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [sale, setSale] = useState(null);
    const headerStyle = {
        backgroundColor: 'green',
        color: 'white',
        padding: '10px',
        textAlign: 'left',
      };
    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Get sale by id
        (async () => {
            try {
                const response = await fetch(`${API}/api/v1/sale/${id}`,
                    { 
                        headers: {
                            'Authorization': localStorage.getItem('jwtToken'), 
                            'Content-Type': 'application/json' 
                        }
            });
                if (!response.ok) {
                    navigate('/sales');
                    return;
                }
                const data = await response.json();
                setSale(data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                navigate('/sales');
                return;
            }
        })();
    }, [navigate, id]);

    return (
        <div className="detailSale-container">

            <div className="text">Details of the sale{" #" + id}</div>

            {!isLoading ? (
                <>
                    <div className="top-sale">
                      
                        <table className='details-head'>
                            <thead>
                                <tr>
                                    <td>
                                        Customer
                                    </td>
                                    <td>
                                        Date
                                    </td>
                                    <td>
                                        Bill Invoice Number
                                    </td>
                                    
                                    
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>
                                    {sale ? sale.customer.name : ""}
                                    </td>
                                    <td>
                                       
                                       {sale ? sale.saleDate:""}
                                    </td>
                                    <td>
                                        {sale ? sale.billInvoiceNumber:""}
                                    </td>
                                    
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-container">
                        <table className="table">
                            <thead style={headerStyle}>
                                <tr>
                                    <th>ID</th>
                                    <th>NAME</th>
                                    <th>MODEL NUMBER</th>
                                    <th>ITEM CODE</th>
                                    <th>QUANTITY</th>
                                    <th>PRICE</th>
                                    <th>SUBTOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sale && sale.saleArticles.map(articleData => (
                                    <tr key={articleData.article.articleId}>
                                        <td>{articleData.article.articleId}</td>
                                        <td>{articleData.article.name}</td>
                                        <td>{articleData.article.modelNumber}</td>
                                        <td>{articleData.article.itemCode}</td>
                                        <td>{articleData.articleQuantity}</td>
                                        <td>{(articleData.price / articleData.articleQuantity)}</td>
                                        <td>{articleData.price}</td>
                                    </tr>
                                ))}
                                {sale &&
                                    <tr>
                                        <td colSpan="5  "></td>
                                        <td className="total">TOTAL</td>
                                        <td className="total">{sale.totalBeforeDiscount}</td>
                                    </tr>
                                }
                                 {sale &&
                                    <tr>
                                        <td colSpan="5  "></td>
                                        <td className="total">DISCOUNT</td>
                                        <td className="total">{sale.discount}</td>
                                    </tr>
                                }
                                {sale &&
                                    <tr>
                                        <td colSpan="5  "></td>
                                        <td className="total">NET</td>
                                        <td className="total">{sale.totalValue}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <Loading />
            )}

        </div>
    );
}

export default DetailSale;
