import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCartPlus,faHandHoldingDollar,faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './sales.css';
import '../../styles/addbox.css';
import SearchBox from '../../components/search-box/SearchBox';
import Pagination from '../../components/pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import userVerification from '../../utils/userVerification';
import { API } from '../../env';
import formatDate from '../../utils/formatDate';
import Loading from '../../components/loading/Loading';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sales = () => {
    localStorage.setItem('selectedView', 'sales'); 
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    
    const pageSize = 20;

    const [isLoading, setIsLoading] = useState(true);

    const [paginator, setPaginator] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [saleToDelete, setSaleToDelete] = useState(null);

    const navigate = useNavigate();
    toast.configure();
    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Query paginated data
        const data = new FormData();
        if (query.length > 0) {
            data.append('searchCriteria', query);
        }
        data.append('page', page);
        data.append('pageSize', pageSize);

        const url = new URL(`${API}/api/v1/sale`);
        url.search = new URLSearchParams(data).toString();
        (async () => {
              await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            })
                .then(response => response.json())
                .then(data => {
                    setPaginator(data);
                    setIsLoading(false);
                })
                .catch(error => console.log(error))
        })();
    }, [navigate, query, page]);

    const handleSearch = (query) => {
        setQuery(query);
    }

    const handlePage = (page) => {
        setPage(page);
    }

    const handleDeleteClick = (saleId) => {
        setSaleToDelete(saleId);
        setShowConfirm(true);
    }

    const confirmDelete = async () => {
        if (!saleToDelete) return;
    
        // Optimistically remove sale from state
        setPaginator(prevState => ({
            ...prevState,
            sales: prevState.sales.filter(sale => sale.saleId !== saleToDelete)
        }));
    
        try {
            const response = await fetch(`${API}/api/v1/sale/${saleToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'),
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                console.log('Sale Deleted successfully');
                toast.warn("Sale Deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                });
    
                // Optionally re-fetch data if needed
                // setIsLoading(true);
                // fetchSalesData(); // Define fetchSalesData() function as needed
            } else {
                console.error('Failed to delete the sale');
                // Re-add sale to state if deletion failed
                setPaginator(prevState => ({
                    ...prevState,
                    sales: [...prevState.sales, { saleId: saleToDelete }] // You may need to fetch full sale details if needed
                }));
            }
        } catch (error) {
            console.error('Error:', error);
            // Re-add sale to state if an error occurred
            setPaginator(prevState => ({
                ...prevState,
                sales: [...prevState.sales, { saleId: saleToDelete }] // You may need to fetch full sale details if needed
            }));
        } finally {
            setShowConfirm(false);
            setSaleToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirm(false);
        setSaleToDelete(null);
    };

    const ConfirmationPopup = ({ show, onConfirm, onCancel }) => {
        if (!show) return null;

        return (
            <div className="confirmation-popup">
                <div className="confirmation-popup-content">
                    <p>Are you sure you want to delete?</p>
                    <button className="yes-btn" onClick={onConfirm}>Yes</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        );
    };

    return (
        <div className="sales-container">

            <div className="text">Sales <FontAwesomeIcon icon={faHandHoldingDollar} className="sales-icon" /> </div>

            <div className="options">
                <SearchBox onSearch={handleSearch} disabled={isLoading} />
                <Link to="/new-sale" className="add-box">
                    <FontAwesomeIcon icon={faPlus} className="icon" />
                                
                    <span className="text">New Sale  </span>
                </Link>
            </div>

            {!isLoading ? (
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATE</th>
                                <th>TOTAL</th>
                                <th>CUSTOMER</th>
                                <th>USER</th>
                                <th>DETAILS</th>
                                <th>DELETE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginator.sales && paginator.sales.length > 0 ? (
                                paginator.sales.map(sale => (
                                    <tr key={sale.saleId}>
                                        <td>{sale.saleId}</td>
                                        <td>{formatDate(sale.saleDate)}</td>
                                        <td>{sale.totalValue}</td>
                                        <td>{sale.customer.name}</td>
                                        <td>{sale.user.name}</td>
                                        <td>
                                            <Link to={`/detail-sale/${sale.saleId}`}>
                                                <FontAwesomeIcon icon={faCartPlus} className="details-icon" />
                                            </Link>
                                        </td>
                                        <td>
                                            
                                         <FontAwesomeIcon 
                                                icon={faTrashCan} 
                                                className="trash-icon" 
                                                onClick={() => handleDeleteClick(sale.saleId)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination paginator={paginator} onChangePage={handlePage} />
                </div>
            ) : (
                <Loading />
            )}
             <ConfirmationPopup 
                show={showConfirm}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />

        </div>
    );
}

export default Sales;
