import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userVerification from '../../../utils/userVerification';
import '../../../styles/new-edit-form.css';
import { API } from '../../../env';
import './new-purchase.css';
import ItemSelection from './item-selection/ItemSelection';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




const NewPurchase = () => {
    localStorage.setItem('selectedView', 'purchases');
    const { providerId } = useParams();
    const navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [provider, setProvider] = useState(null);

    const [formData, setFormData] = useState({
        providerId: 0,
        articles: [],
        sessionUserId: 0,
        billInvoiceNumber:'',
        purchaseDate:new Date().toISOString().split('T')[0] ,
        discount:0
    });
    toast.configure();
    useEffect(() => {
        // Permission validation
        const userVer = userVerification();
        if (!userVer.isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Get provider by id
        (async () => {
            try {
                const response = await fetch(`${API}/api/v1/provider/${providerId}`,{
                  
                    headers: {
                        'Authorization': localStorage.getItem('jwtToken'), 
                    }
                    });
                if (!response.ok) {
                    navigate('/new-purchase');
                    return;
                }
                const data = await response.json();
                setProvider(data);
                // Initialize form data
                setFormData({
                    ...formData,
                    providerId: providerId,
                    sessionUserId: userVer.user.userId
                });
            } catch (error) {
                console.log(error);
                navigate('/new-purchase');
                return;
            }
        })();
        // eslint-disable-next-line
    }, [navigate, providerId]);

    const onSelectionChange = (articles) => {
        setFormData({
            ...formData,
            articles: articles.map(a => ({articleId: a.articleId, articleQuantity: a.quantity,purchasePrice:a.purchasePrice}))
        });
    }
      // Handler for invoice bill number change
      const handleInvoiceBillNumberChange = (billInvoiceNumber) => {
        setFormData(prevData => ({
            ...prevData,
            billInvoiceNumber
        }));
    };

    // Handler for sale date change
    const handlePurchaseDateChange = (purchaseDate) => {
        setFormData(prevData => ({
            ...prevData,
            purchaseDate
        }));
    };

     // Handler for sale date change
     const handleDiscountChange = (discount) => {
        setFormData(prevData => ({
            ...prevData,
            discount
        }));
        };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.articles.length === 0) {
            alert('Debe seleccionar al menos un artículo');
            return;
        }
        setSubmitDisabled(true);
        try {
            const response = await fetch(`${API}/api/v1/purchase`, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Purchase registered successfully');
                toast.success("Category created successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                  });
                navigate('/purchases');
                return;
            }
            toast.error("The purchase could not be registered, please verify the data", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              });
           // alert("The purchase could not be registered, please verify the data");
        } catch (error) {
            console.log(error);
            alert("Error registering purchase");
        }
        setSubmitDisabled(false);
    }

    return (
        <div className="newPurchase-container">

            <div className="text"> New Purchase</div>
            <div className="form-container">
                <form onSubmit={handleSubmit} autocomplete="off">

                    <ItemSelection onSelectionChange={onSelectionChange} 
                     onInvoiceBillNumberChange={handleInvoiceBillNumberChange}
                     onPurchaseDateChange={handlePurchaseDateChange} 
                     provider={provider} 
                     onDiscountChange={handleDiscountChange} />

                    {formData.articles.length > 0 && (
                        <div className="button-container">
                            <button className="btn" type="submit" disabled={submitDisabled}>
                            Create
                            </button>
                        </div>
                    )}
                </form>
            </div>

        </div>
    );
}

export default NewPurchase;
